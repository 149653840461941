import React from 'react';
import { CookiesProvider } from 'react-cookie';

import { Provider } from './ui/provider';

import { CloudinaryProvider } from '../context/CloudinaryProvider';

const RootElement = ({ children }) => {
  return (
    <CookiesProvider>
      <Provider>
        <CloudinaryProvider>{children}</CloudinaryProvider>
      </Provider>
    </CookiesProvider>
  );
};

export default RootElement;
